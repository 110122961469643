import React, { useState, useEffect } from 'react';
import { mobileDetect } from "../Utils/mobileDetect";
import { SjContainer, SjCol, SjRow } from '../Components/grid';
import { SjButton } from '../Components/button';
import { SjText } from '../Components/text';
import { SjHeading } from '../Components/heading';
import { SjExternalLink, SjLink } from '../Components/link';
import { ISjMapMarker, SjMap } from '../Components/map';
import { HttpHelper } from '../http/http';
import { sanitize, HtmlStyledComponent } from '../Utils/sanitize';
import { SjCard } from '../Components/card';
import { } from 'react-dom/client';
import { useNavigate } from 'react-router-dom';
import { AdSpace } from './Adspace.component';
import { StaticAdSpace } from './AdSpaceStatic.component';

const imgStyle = {
  marginTop: 20,
  marginBottom: -10,
  zIndex: 8000,
  minHeight: mobileDetect.mobile() ? '100px' : '200px',
  width: '100%',
  height: 'auto',
  maxHeight: 'auto',
};
export const HomePage = () => {
  const [motd, setMotd] = useState('');
  const [markers, setMarkers] = useState<ISjMapMarker[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const load = async () => {
      try {
        const { content } = await HttpHelper.GET('pages/motd');
        if (content)
          setMotd(content);
      } catch (e) {

      }
    }
    load();

    const getMarkers = async () => {
      try {
        const { results } = await HttpHelper.GET('organizers');
        const temp: any = [];
        results.forEach((organizer: any) => {
          organizer.kommuner.forEach((kommune: any) => {
            if (kommune.location && kommune.location.length === 2) {
              kommune.lat = kommune.location[1];
              kommune.lng = kommune.location[0];
              kommune.latLng = { lat: kommune.lat, lng: kommune.lng };
              if (temp.findIndex((i: any) => i.id === kommune.id) === -1 && kommune.lat && kommune.lng) {
                temp.push(kommune);
              }
            }
          });
        });
        setMarkers(temp);
      }
      catch (e) {

      }
    }
    getMarkers();

  }, []);

  return (
    <div style={{ flex: 1 }}>
      <div style={{ backgroundColor: '#66ccff', padding: 0, position: 'relative' }}>
        <div className="righteous-container">
          <h1 className="righteous-fontfam righteous-heading" style={{ lineHeight: '0.5', color: 'white', marginBottom: 0 }} >Stolpejakten</h1>
          <h1 className="righteous-fontfam righteous-year" style={{ color: '#424242', marginTop: 0, marginBottom: 0 }} >2024</h1>
          <h1 className="righteous-fontfam righteous-hashtag" style={{ lineHeight: '0.5', color: 'white', marginTop: 0 }} >#stolpejakten</h1>
        </div>
        <img
          src={
            mobileDetect.mobile()
              ? '/assets/images/landing-tiny-mobile.png'
              : '/assets/images/landing-tiny.png'
          }
          style={imgStyle}
          alt='Stolpejakten'
        />
      </div>
      <div>
        <AdSpace />
      </div>
      <SjContainer>
        <SjRow>
          <SjCol sm={12} md={12} lg={12} style={{ textAlign: 'center', padding: '2em 1em' }}>
            <SjButton title="Kom i gang!" color="purple" onClick={() => navigate('/ny')} />
            <SjButton title="Logg inn" color="blue" icon="sign-in-alt" onClick={() => navigate('/logginn')} />
          </SjCol>
        </SjRow>
      </SjContainer>
      <SjContainer>
        <SjRow>
          <SjCol sm={6} md={6} lg={6} style={{ textAlign: 'center', padding: '2em 1em' }}>
            <SjText bold large>Besøk tusenvis av stolper i Norge</SjText>
          </SjCol>
          <SjCol sm={6} md={6} lg={6} style={{ textAlign: 'center', padding: '2em 1em' }}>
            <SjText bold large>Samle poeng, prestasjoner og merker. Klatre på topplister og poengtavler!</SjText>
          </SjCol>
        </SjRow>
      </SjContainer>

      <div style={{ display: 'flex', paddingLeft: '1em', paddingRight: '1em', flexWrap: 'wrap' }}>
        <div style={{ flex: 2, paddingRight: 10, minWidth: 300 }}>
          <SjHeading style={{ marginTop: 0 }} h="h3">Hva er Stolpejakten?</SjHeading>
          <SjText>Stolpejakten går ut på å finne stolper sentralt plassert i ulike kommuner. Stolpebesøk kan registreres på papir, nettsiden eller via appen vår. Vi tar sikte på å legge til rette for å aktivisere både unge og gamle, uansett funksjonsevne. Se video på <a href="https://www.youtube.com/watch?v=TuVV20_xBMs" target="_blank">Youtube</a> for mer om Stolpejakten. <SjLink to="/konseptet">Les mer om Stolpejakten</SjLink></SjText>
          <SjHeading h="h3">Se hvor Stolpejakten arrangeres</SjHeading>
          <SjText>I år arrangerer vi stolpejakt i enda flere kommuner enn i fjor. Oppstart varierer fra arrangør til arrangør, sjekk <SjLink to="/omraader">områdeoversiken</SjLink> for full oversikt over sesongstart og avslutning. Vi får ofte nye arrangører utover sesongen, så følg med for ytterligere stolpejakt andre steder i landet.</SjText>
        </div>
        <div style={{ flex: 1, minWidth: 300 }}>
          {markers?.length > 0 ? <SjMap containerId="map-container" updateOnLayerChange={false} markers={markers} /> : null}
        </div>
      </div>
      <StaticAdSpace />
      {motd ? <div style={{ margin: 10, textAlign: 'center' }}><SjCard><HtmlStyledComponent dangerouslySetInnerHTML={sanitize(motd)} /></SjCard></div> : null}
      <SjContainer>
        <SjRow>
          <SjCol sm={12} md={12} lg={12} style={{ textAlign: 'center', padding: '2em 1em' }}>
            <SjHeading h="h2">Last ned Stolpejakten-appen</SjHeading>
            <div style={{ whiteSpace: 'nowrap' }}>
              <a
                target='_blank'
                href='https://itunes.apple.com/no/app/stolpejakten/id871144690?mt=8'
                className='sj-app-store-icon ios'
              >
                <img
                  style={{ height: 'auto', width: '100%' }}
                  src='/assets/images/appstore-lrg.svg'
                />
              </a>
              <a
                target='_blank'
                href='https://play.google.com/store/apps/details?id=no.andmark.sig'
                className='sj-app-store-icon android'
              >
                <img
                  style={{ height: 'auto', width: '100%' }}
                  src='/assets/images/no_badge_web_generic.png'
                />
              </a>
            </div>
          </SjCol>
        </SjRow>
      </SjContainer>
      <SjContainer>
        <SjRow>
          <SjCol sm={0} md={2} lg={2} />
          <SjCol sm={12} md={8} lg={8} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', padding: '2em 1em' }}>
            <SjHeading h="h2">Turorientering</SjHeading>
            {/*<div>
              <img style={{ maxWidth: 100 }} src="https://static.turorientering.no/img/logo.png?v=2020" title="turo-logo" />
        </div>*/}
            <SjText large bold>Flere arrangører av Stolpejakten har også tilbud om turorientering.</SjText>
            <SjText large bold>Se mer på <SjExternalLink href="https://turorientering.no" >turorientering.no</SjExternalLink></SjText>
          </SjCol>
          <SjCol sm={0} md={2} lg={2} />
        </SjRow>
      </SjContainer>
    </div>
  )
}