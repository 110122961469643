import MobileDetect from 'mobile-detect';
const mobileDetect = new MobileDetect(window.navigator.userAgent);

const browserIsIE = () => {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10) ? true : false;
  }
  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10) ? true : false;
  }
  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10) ? true : false;
  }
  return false;
};

const isIE = browserIsIE();

export { mobileDetect, browserIsIE, isIE };
