import React from "react";
import styled from "styled-components";
const Select = styled.select`
  width: 100%;
  height: 65px;
  margin-bottom:10px;
  color: ${props => props.theme.colors.blue};
  padding-left: 15px;
  font-size: 1.3em;
  font-weight: bold;
  border: 1px solid ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.backgroundColor} !important;
  border-radius: 3px;
  margin-left: 0px;
  display:block;
  &:disabled {
    opacity:0.5;
  }

  option {
    font-size: 1.1em;
    font-weight: bold;
    display: flex;
    color ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.white};
    white-space: pre;
    min-height: 20px;
    padding: 0px 4px 2px;
    padding-left:15px;
  }
`;

interface IOptionProp {
  key: any;
  value: string;
  text: string;
}

interface IProps {
  options: IOptionProp[];
  onChange?: any;
  title: string;
  defaultValue: string;
  ariaLabel?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export const SjSelect = (props: IProps) => {
  const onSelect = (el: any) => props.onChange(el.target.value);
  return <Select disabled={props.disabled} aria-label={props.ariaLabel} onChange={onSelect} defaultValue={props.defaultValue} style={props.style}>
    <option value="-1" aria-label={props.title}>{props.title}</option>
    {props.options.map((o: IOptionProp) => {
      return <option key={o.key} value={o.value}>{o.text}</option>
    })}
  </Select>
}