import { SET_AUTH_STATE, SET_MODAL_STATE, SET_SNACKBAR_STATE } from "../constants";

const initialState = {
  authorized: false,
  modalOpen: false,
  modalContent: '',
  confirmClose: false,
  confirmCloseText: '',
  snackbarOpen: false,
  snackbarText: ''
};

function rootReducer(state = initialState, action: any) {
  if (action.type === SET_AUTH_STATE) {
    return { ...state, authorized: action.payload.authorized };
  }
  if (action.type === SET_MODAL_STATE) {
    return { ...state, modalOpen: action.payload.modalOpen, modalContent: action.payload.modalContent, confirmClose: action.payload.confirmClose, confirmCloseText: action.payload.confirmCloseText };
  }
  if (action.type === SET_SNACKBAR_STATE) {
    return { ...state, snackbarOpen: action.payload.snackbarOpen, snackbarText: action.payload.snackbarText };
  }
  return state;
};

export default rootReducer;