import React from 'react';
import styled from "styled-components";

const StyledCard = styled.div`
  color: ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.black};
  font-weight:bold;
  padding:20px;
  padding-top:10px;
  max-width:100%;
  box-shadow: 0 1px 2px 0 ${props => props.theme.colors.card.hover};
  transition: 0.3s;
  &:hover {
    box-shadow: 0 2px 4px 0 ${props => props.theme.colors.card.hover};
  }
  margin-bottom:50px;
  vertical-align:top;
  h1,h2,h3 {
    margin-top:0;
  }
`;

export const SjCard = ({ children }: any) => {
  return <StyledCard>{children}</StyledCard>
}