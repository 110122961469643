import { HttpHelper } from "./http";
import { SjLogger } from "../Utils/logger";
import { SjStorage } from "../Utils/storage";

export const UserApi = {
  getMe: async () => {
    const me = await HttpHelper.GET('users/me', true, true);
    const parsed = JSON.stringify(me);
    SjStorage.setItem('me', parsed);
    return me;
  },
  getFamily: async () => {
    return await HttpHelper.GET('users/me/family', true, true);
  },
  addFamilyMember: async ({ name, yob, gender }: any) => {
    return await HttpHelper.POST('users/me/family', { name, yob, gender });
  },
  removeFamilyMember: async (id: string) => {
    return await HttpHelper.DEL('users/me/family', { id });
  },
  authorized: async () => {
    try {
      await HttpHelper.GET('users/ping', true, true);
    } catch (e) {
      SjLogger.log('Unauthorized error', e);
      throw new Error();
    }
  },
  changeUserData: (field: string, data: any) => HttpHelper.POST('users/me/' + field, data),
  deleteMe: (password: string) => HttpHelper.POST('users/me/delete', { password }),
}