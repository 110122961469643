import React, { useState, useEffect } from 'react';
import Modal from 'styled-react-modal';
import { SjButton } from './button';
import { withModal } from './reduxHoc';
import { render } from 'react-dom';
import { SjLogger } from '../Utils/logger';

const StyledModal = Modal.styled`
  width: 90%;
  height: 80%;
  margin-top:8%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:column;
  z-index:10000;
  padding:15px;
  border-radius:3px;
  color: ${(props: any) => props.theme.colors.primary};
  background-color: ${(props: any) => props.theme.colors.backgroundColor};
`

const ModalComponent = (props: any) => {
  const toggleModal = () => {
    SjLogger.log('toggleModal', props);
    if (props.modalOpen === true && props.confirmClose) {
      const ok = window.confirm(props.confirmCloseText);
      if (ok) {
        props.setModalState(!props.modalOpen);
      }
      return;
    }

    props.setModalState(!props.modalOpen);
  }

  return (
    <StyledModal
      isOpen={props.modalOpen}
      onBackgroundClick={toggleModal}
      onEscapeKeydown={toggleModal}>
      <div style={{ flex: 1, overflowY: 'auto', maxHeight: '100%', width: '100%' }} id="modal-content">
        {props.modalContent}
      </div>
      <div style={{ flex: 0, width: '100%' }}>
        <SjButton style={{ width: '100%' }} color="purple" onClick={toggleModal} title="Lukk" />
      </div>
    </StyledModal>
  )
};

const SjModal = withModal(ModalComponent);
export { SjModal };