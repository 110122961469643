import React from 'react';
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SjText } from './text';

const Text = styled.div`
  color: ${props => props.theme.colors.white};
  display: block;
`;

const StyledContainer = styled.div`
  color: ${props => props.theme.colors.purple};
  font-weight:bold;
  padding:15px;
  max-width:100%;
  text-align:center;
`;

interface IProps {
  message?: string;
  loading: boolean;
}

export const SjLoadingIndicator = ({ message = "", loading }: IProps) => {
  if (!loading) {
    return null;
  }
  return <StyledContainer>
    <FontAwesomeIcon size="2x" icon='spinner' spin={true} /> {message ? <SjText bold>{message}</SjText> : null}
  </StyledContainer>
}