import { Default } from "./default";

export const Dark = {
  ...Default,
  colors: {
    main: '#99CC00',
    primary: '#ffffff',
    black: 'black',
    border: 'red',
    red: '#ff0033',
    success: '#4BB543',
    backgroundColor: '#121212',
    purple: '#9C27B0',
    blue: '#0090ff',
    select: '#1A1A1A',
    card: {
      hover: 'rgba(0,0,0,1)'
    },
    table: {
      trOdd: '#1A1A1A',
      trEven: '#121212',
      th: 'black',
      text: '#ffffff'
    }
  },
  diffColors: [
    '#ffffff',
    '#99CC00',
    '#0090ff',
    'red',
    'black'
  ]
}