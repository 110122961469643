import React from 'react';
import { SjIcon } from './icon';
import styled from "styled-components";

const Tooltip = styled.span`
  visibility: hidden;
  width: 250px;
  max-width:250px;
  font-size:1em;
  font-weight:bold;
  bottom: 100%;
  left: 50%;
  text-align:left;
  margin-left: -140px;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.blue};
  text-align: center;
  padding: 15px;
  border-radius: 3px;
  position: absolute;
  z-index: 1000;

  p {
    text-align:left;
    padding: 0;
    margin: 0;
  }

  ::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 6px;
    border-style: solid;
    border-color: ${props => props.theme.colors.primary} transparent transparent transparent;
  }
`

const InfoCircle = styled.div`
  position: relative;
  display: inline-block;
  margin-left:5px;
  cursor:pointer;
  &:hover {
    > span {
      visibility: visible;
    }
  }
`;

export const SjInfoCircle = ({ children = "" }) => (<InfoCircle>
  <SjIcon icon="info-circle" color="#0090ff" />
  <Tooltip><p>{children}</p></Tooltip>
</InfoCircle>)