import React from 'react';
import styled from "styled-components";
import sanitizeHtml from 'sanitize-html';

const defaultOptions = {
  allowedTags: ['span', 'p', 'strong', 'a', 'div', 'br', 'ul', 'li', 'h1', 'h2', 'h3', 'h4', 'h5', 'img'],
  allowedAttributes: {
    'a': ['href'],
    'img': ['src', 'width', 'height']
  },
  allowedIframeHostnames: ['youtube.com']
};

export const sanitize = (dirty) => ({ __html: sanitizeHtml(dirty, defaultOptions) });

export const HtmlStyledComponent = styled.div`
  word-wrap: break-word;
  color: ${props => props.theme.colors.primary};
  font-size: 1em;
  a {
    color: ${props => props.theme.colors.purple};
    text-decoration: none;
    font-weight:bold;
    &:hover {
      color: ${props => props.theme.colors.purple};
    }
  }
  h1,h2,h3,h4,h5 {
    color: ${props => props.theme.colors.main};
  }
  vertical-align:top;
  flex:0;
  img {
    height:auto;
    max-width:400px;
    display:inline-block;
  }
`;