import React from "react";
import styled from "styled-components";

const Page = styled.div`
  flex: 1;
  padding: 20px;
  @media (max-width: 768px) {
    padding:10px;
  }
`;

export const SjPageContainer = ({ children }: any) => <Page>{children}</Page>