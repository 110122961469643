import { SET_AUTH_STATE, SET_MODAL_STATE, SET_SNACKBAR_STATE } from "../constants";
import { SjStorage } from "../../Utils/storage";

export function setAuthorized(authorized: boolean = false) {
  if (!authorized) {
    SjStorage.removeItem('token');
    SjStorage.removeItem('me');
  }
  return { type: SET_AUTH_STATE, payload: { authorized } };
}

export function setModalState(modalOpen: boolean = false, modalContent: any = '', confirmClose = false, confirmCloseText = '') {
  return { type: SET_MODAL_STATE, payload: { modalOpen, modalContent, confirmClose, confirmCloseText } };
}

export function setSnackbarState(snackbarOpen: boolean = false, snackbarText: string = '') {
  return { type: SET_SNACKBAR_STATE, payload: { snackbarOpen, snackbarText } };
}