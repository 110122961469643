import React from "react";
import styled from "styled-components";

const CheckBoxWrapper = styled.div`
  position: relative;
`;
const CheckBoxBg = styled('label') <{ id?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
const CheckBox = styled('input') <{ id?: string, childid?: string }>`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;  
  height: 26px;
  &:checked + #${(props) => props.childid} {
    background: #4fbe79;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

interface IProps {
  defaultValue?: boolean
  onClick?: any;
  onChange: any;
  style?: any;
}

export const SjToggle = (props: IProps) => {
  const onChange = (e: any) => { props.onChange(e.target.value); }
  return (
    <CheckBoxWrapper style={props.style}>
      <CheckBox id="toggle" type="checkbox" childid="toggle2" checked={props.defaultValue} onClick={props.onClick} onChange={onChange} />
      <CheckBoxBg id="toggle2" htmlFor="toggle" />
    </CheckBoxWrapper>
  )
}


interface ICheckboxProps {
  onChange: any;
  checked?: boolean;
  id: string;
}

export const SjCheckbox = (props: ICheckboxProps) => {
  const onChange = (e: any) => { props.onChange(e.target.value); }
  return (
    <CheckBoxWrapper id={"checkbox_ct_" + props.id}>
      <CheckBox id={"checkbox_" + props.id} childid={"checkbox_bg_" + props.id} type="checkbox" checked={props.checked} onChange={onChange} />
      <CheckBoxBg id={"checkbox_bg_" + props.id} htmlFor={"checkbox_" + props.id} />
    </CheckBoxWrapper>
  )
}