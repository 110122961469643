import { Default } from "./default";

export const Light = {
  ...Default,
  colors: {
    main: '#99CC00',
    primary: '#424242',
    black: '#ffffff',
    border: 'blue',
    red: '#ff0033',
    success: '#4BB543',
    backgroundColor: '#f8f8f8',
    purple: '#9C27B0',
    blue: '#0090ff',
    select: '#f8f8f8',
    card: {
      hover: 'rgba(0,0,0,0.4)'
    },
    table: {
      trEven: '#f8f8f8',
      trOdd: '#ffffff',
      th: 'white',
      text: 'black'
    }
  },
  diffColors: [
    'white',
    '#99CC00',
    '#0090ff',
    'red',
    'black'
  ]
}