import React from "react";
import styled from "styled-components";

export const SjList = styled.ul`
  color: ${props => props.theme.colors.blue};
  font-size: 2.5em;
`;

export const SjListItem = styled.li`
  color: ${props => props.theme.colors.blue};
  font-size: 18px;
`;

