import React from 'react';
import styled from "styled-components";
import { SjIcon } from './icon';

const DiffText = styled("div") <{ difficulty: number }>`
  color: ${props => props.theme.diffColors[props.difficulty]};
  display: inline-block;
  margin-bottom:10px;
`;

const Text = styled.div`
  color: ${props => props.theme.colors.primary};
  display: inline-block;
  margin-bottom:10px;
`;

const TooltipContainer = styled.div`
  color: ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.blue};
  display: flex;
  padding:15px;
  padding-bottom:5px;
  font-weight:bold;
  margin-top:10px;
  margin-bottom:10px;
  border-radius:3px;

  svg {
    
  }
`;

interface ITextProps {
  children: React.ReactNode | String,
  fontSize?: any;
  style?: any;
  bold?: boolean;
  large?: boolean;
}

interface IDiffTextProps {
  children: React.ReactNode | String,
  fontSize?: any;
  style?: any;
  bold?: boolean;
  large?: boolean;
  difficulty: number;
}

export const SjText = (props: ITextProps) => {
  return <Text style={{ fontWeight: !props.bold ? 'normal' : 'bold', fontSize: props.fontSize ? props.fontSize : (props.large ? '1.4em' : '1.2em'), ...props.style }}>{props.children}</Text>
}

export const SjTooltip = (props: ITextProps) => {
  return <TooltipContainer><SjIcon icon="info-circle" size="lg" style={{ display: 'inline-block' }} /><Text style={{ marginLeft: 5, fontSize: '1.1em', ...props.style }}>{props.children}</Text></TooltipContainer>
}

export const SjDiffColorText = (props: IDiffTextProps) => {
  return <DiffText difficulty={props.difficulty} style={{ fontWeight: !props.bold ? 'normal' : 'bold', fontSize: props.fontSize ? props.fontSize : (props.large ? '1.4em' : '1.2em'), ...props.style }}>{props.children}</DiffText>
}