import React from 'react';
import styled from 'styled-components';

const StyledTable = styled.table<any>`
flex:1;
width:100%;
border-collapse: collapse;
overflow-x:${props => props.maxwidth ? 'hidden' : 'auto'};
overflow-y:${props => props.maxwidth ? 'hidden' : 'auto'};
tbody > tr:hover {
  transform: scale(1.02);
}
`;
const Tr = styled.tr`
transition: transform .2s;
&:nth-child(even) {
  background-color: ${props => props.theme.colors.table.trEven};
  color: ${props => props.theme.colors.table.text};
}
&:nth-child(odd) {
  background-color: ${props => props.theme.colors.table.trOdd};
  color: ${props => props.theme.colors.table.text};
}`;
const StyledTh = styled.th<any>`
  text-align:left;
  font-size:1.3em;
  color: ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.black};
  padding:${props => props.$condensed ? '10px' : '20px'};
  @media (max-width: 768px) {
    display:block;
    padding:10px;
    padding-left:5px;
  }
`;

const Th = (props: any) => {
  return (<StyledTh $condensed={props.condensed} colSpan={props.colSpan}>{props.children}</StyledTh>)
}

const Td = styled.td<any>`
text-align:left;
padding:${props => props.condensed ? '10px' : '20px'};
font-weight:bold;
vertical-align:top;
border: none;
@media (max-width: 768px) {
  display:block;
  padding:5px;
}
`;
const TBody = styled.tbody``;

const TBodyWithScroll = styled.tbody`

`;

const THead = styled.thead`
  background-color: ${props => props.theme.colors.black};
`;

const TableContainer = styled.div<any>`
  width:100%;
  max-width: ${props => props.maxWidth ? props.maxWidth : '100%'};
  min-widh: 1100px;
`;

const TableContainerWithScroll = styled.div<any>`
  max-height: 500px;
  overflow-y:auto;
`;

const Table = (props: any) => {
  return <StyledTable className={props.condensed ? 'table-condensed' : ''} maxwidth={props.maxWidth} style={props.style}>
    {props.children}
  </StyledTable>
}

export { TableContainer, Table, Th, Tr, Td, TBody, THead, TBodyWithScroll, TableContainerWithScroll };