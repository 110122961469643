import { SjLogger } from "./logger";


const localStorageAvailable = () => {
  var test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

export const SjStorage = {
  setItem: (key: string, val: string) => {
    SjLogger.log('[LocalStorage] SET', key, val);
    localStorage.setItem('sj:' + key, val);
  },
  getItem: (key: string, defaultValue: string = '') => {
    const val = localStorage.getItem('sj:' + key);
    SjLogger.log('[LocalStorage] GET', key);
    if (val !== null) {
      return val;
    }
    return defaultValue;
  },
  removeItem: (key: string) => {
    SjLogger.log('[LocalStorage] REMOVE', key);
    localStorage.removeItem('sj:' + key);
  }
}
