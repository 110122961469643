import React from "react";
import styled from "styled-components";
import { SjIcon } from "./icon";

export const Label = styled.label`
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom:5px;
  color: ${props => props.theme.colors.primary};
  display: inline-block;
  text-align:left !important;
`;

const Input = styled.input`
  color: ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.black};
  display: inline-block;
  padding: 20px 15px;
  font-size: 1.2em;
  font-weight: bold;
  width:100%;
  display: block;
  margin-bottom:10px;
  border: 1px solid ${props => props.theme.colors.primary};
  flex:1;
  box-sizing : border-box;
  outline-offset: 0;
  border-radius: 3px;
  margin-left:0;
  margin-right:0;
  &:focus {
    outline-style: solid;
    outline-width: 3px;
    outline-color: ${props => props.theme.colors.blue};
    outline-offset: -2px;
  }
  &:disabled {
    opacity:0.5;
  }
`;

interface IInputProps {
  value?: string;
  placeholder: string;
  onChange?: Function,
  onEnter?: Function,
  type?: string,
  label?: string,
  hideLabel?: boolean,
  style?: any,
  onClear?: any,
  autoComplete?: string
  disabled?: boolean;
}

export const SjInput = (
  { value = "",
    placeholder = "",
    onChange = (val: any) => { },
    onEnter = () => { },
    type = "text",
    hideLabel = false,
    label = "",
    style = {},
    onClear,
    disabled = false,
    autoComplete = 'off'
  }: IInputProps
) => {
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (typeof onEnter === 'function') {
        onEnter();
      }
    }
  }

  if (typeof onClear !== 'undefined') {
    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <Input disabled={disabled} autoComplete={autoComplete} onKeyDown={handleKeyDown} onChange={(e: any) => onChange(e.target.value)} type={type} placeholder={placeholder} value={value} style={style} />
        {value.length ? <div onClick={() => onClear()} style={{ cursor: 'pointer', position: 'absolute', right: 15, top: 15 }}><SjIcon icon="times" size='2x' color='white' /></div> : null}
      </div>
    )
  }

  return <div style={{ textAlign: 'left', width: '100%' }}>
    {!hideLabel ? <Label>{!label ? placeholder : label}</Label> : null}
    <Input disabled={disabled} autoComplete={autoComplete} onKeyDown={handleKeyDown} onChange={(e: any) => onChange(e.target.value)} type={type} placeholder={placeholder} value={value} style={style} />
  </div>
}





const TextArea = styled.textarea`
  color: ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.black};
  display: inline-block;
  padding: 20px 15px;
  font-family: 'Lato';
  font-size: 1.2em;
  font-weight: bold;
  width:100%;
  display: block;
  margin-bottom:10px;
  border: 1px solid ${props => props.theme.colors.primary};
  flex:1;
  box-sizing : border-box;
  outline-offset: 0;
  border-radius: 3px;
  margin-left:0;
  margin-right:0;
  &:focus {
    outline-style: solid;
    outline-width: 3px;
    outline-color: ${props => props.theme.colors.blue};
    outline-offset: -2px;
  }
  &:disabled {
    opacity:0.5;
  }
`;


export interface ITextAreaProps extends IInputProps {
  cols?: number;
  rows?: number;
}

export const SjTextArea = (
  { value = "",
    placeholder = "",
    onChange = (val: any) => { },
    onEnter = () => { },
    label = "",
    style = {},
    onClear,
    disabled = false,
    autoComplete = 'off',
    cols = 10,
    rows = 10
  }: ITextAreaProps
) => {
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (typeof onEnter === 'function') {
        onEnter();
      }
    }
  }

  return <div style={{ textAlign: 'left', width: '100%' }}>
    <Label>{!label ? placeholder : label}</Label>
    <TextArea cols={cols} rows={rows} disabled={disabled} autoComplete={autoComplete} onKeyDown={handleKeyDown} onChange={(e: any) => onChange(e.target.value)} placeholder={placeholder} value={value} style={style} />
  </div>
}
