import React, { useEffect, useState } from 'react';
import { SjPageContainer } from '../../Components/page';
import { SjHeading } from '../../Components/heading';
import { HttpHelper } from '../../http/http';
import styled from "styled-components";
import { SjError } from '../../Components/error';
import { Helmet } from "react-helmet";
import { sanitize } from '../../Utils/sanitize';

const PageContent = styled.div`
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.font.textSize};
  a {
    color: ${props => props.theme.colors.purple};
    text-decoration: none;
    font-weight:bold;
    &:hover {
      color: ${props => props.theme.colors.purple};
    }
  }
  h1,h2,h3,h4,h5 {
    color: ${props => props.theme.colors.primary};
  }

  h1 {
    @media only screen and (max-width: 768px) {
      font-size: 1.5em;
    }
  }

  h2 {
    @media only screen and (max-width: 768px) {
      font-size: 1.3em;
    }
  }

  h2 {
    @media only screen and (max-width: 768px) {
      font-size: 1.1em;
    }
  }

`;

export const SjPage = ({ title, id }: any) => {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState('');
  const [error, setError] = useState('');

  const load = async () => {

    try {
      const result = await HttpHelper.GET('pages/' + id);
      setContent(result.content);
    }
    catch (e) {
      setError('Kunne ikke hente "' + title + '"');
    }

    setLoading(false);
  }
  useEffect(() => {
    load();
  }, [id]);

  return (
    <SjPageContainer>
      <SjHeading h='h1'>{title}</SjHeading>
      <Helmet
        meta={[
          { charSet: "utf-8" }
        ]}
        title={"Stolpejakten - " + title}
        link={[{ href: "https://stolpejakten.no/" + id, rel: "canonical" }]}
      />
      <SjError message={error} />
      {loading ? <SjHeading h="h4">Laster...</SjHeading> : null}
      {content ? <PageContent dangerouslySetInnerHTML={sanitize(content)} /> : null}
    </SjPageContainer>
  )
}