import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
interface IButtonProps {
  color?: string;
  title?: string;
  onClick?: any,
  disabled?: boolean
  icon?: any;
  style?: any;
  iconLeft?: boolean;
  iconRight?: boolean;
  loading?: boolean;
  buttonStyle?: any;
  ariaLabel?: string;
  iconSize?: SizeProp | undefined;
}

export const SjButton = ({ ariaLabel = '', loading = false, iconSize = '1x', color, onClick = () => { }, disabled = false, title = "", icon, iconLeft = false, iconRight = true, style = {}, buttonStyle = {} }: IButtonProps) => {
  if (iconLeft) {
    iconRight = false;
  }
  if (iconRight) {
    iconLeft = false;
  }
  if (!ariaLabel) {
    ariaLabel = title;
  }
  return (
    <div className={"sj-button-wrapper " + color + (disabled || loading ? 'disabled' : '')} style={style}>
      <button aria-label={ariaLabel} title={title ? title : ariaLabel} className={"sj-button " + color} disabled={loading || disabled} onClick={onClick} style={buttonStyle}>
        {icon && iconLeft ? <FontAwesomeIcon size={iconSize} spin={loading} icon={loading ? 'spinner' : icon} /> : null}
        {' ' + title + ' '}
        {icon && iconRight ? <FontAwesomeIcon size={iconSize} spin={loading} icon={loading ? 'spinner' : icon} /> : null}</button>
    </div>
  )
}
