import React from 'react';
import { Dark } from './dark';
import { Light } from './light';

const defaultTemeContext = {
  dark: false,
  toggle: () => { }
}

const ThemeContext = React.createContext(defaultTemeContext);
const useTheme = () => React.useContext(ThemeContext);
const Theme = (mode: string = 'light') => mode === 'dark' ? Dark : Light;

export { Theme, useTheme, ThemeContext }
