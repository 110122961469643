import React from 'react';
import styled from "styled-components";

const Text = styled.div`
  color: ${props => props.theme.colors.white};
  display: block;
`;

const StyledError = styled.div`
  color: white;
  font-weight:bold;
  padding:15px;
  max-width:100%;
  margin-bottom:10px;
  text-align:left;
  background-color: ${props => props.theme.colors.red};
  width: inherit;
`;

const StyledSuccess = styled.div`
  color: white;
  font-weight:bold;
  padding:15px;
  max-width:100%;
  margin-bottom:10px;
  text-align:left;
  background-color: ${props => props.theme.colors.success};
  width: inherit;
`;

interface IProps {
  message?: string;
  heading?: string;
  messages?: string[];
}

export const SjError = ({ message = "", heading = '', messages = [] }: IProps) => {
  if (!message && !messages.length) {
    return null;
  }
  if (message === 'Failed to fetch') {
    message = 'Kommunikasjonsproblemer. Prøv igjen om litt';
  }
  return <StyledError>
    {heading ? <Text style={{ fontSize: '1.3em', marginBottom: 10 }}>{heading}</Text> : null}
    {messages && messages.length ?
      <span>
        {messages.map((m: string) => <Text key={m}>- {m}</Text>)}
      </span> : null
    }
    {message && message.length ? <Text>{message}</Text> : null}
  </StyledError>
}


export const SjSuccess = ({ message = "", heading = '', messages = [] }: IProps) => {
  if (!message && !messages.length) {
    return null;
  }
  return <StyledSuccess>
    {heading ? <Text style={{ fontSize: '1.3em', marginBottom: 10 }}>{heading}</Text> : null}
    {messages && messages.length ?
      <span>
        {messages.map((m: string) => <Text key={m}>- {m}</Text>)}
      </span> : null
    }
    {message && message.length ? <Text>{message}</Text> : null}
  </StyledSuccess>
}