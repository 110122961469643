import 'react-app-polyfill/ie11';
import React from 'react';
import { createRoot } from 'react-dom/client'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
const container = document.getElementById('root');
createRoot(container!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
serviceWorker.unregister();
