import React from 'react';
import styled from "styled-components";
import { SjContainer, SjCol, SjRow } from './Components/grid';
import { Link } from 'react-router-dom';

const Footer = styled.div`
  background-color:${props => props.theme.colors.backgroundColor};
  min-height:200px;
  padding:20px;
  margin-top:50px;
  border-top: 1px solid ${props => props.theme.colors.black};
  & a {
    text-decoration: none;
    font-weight: bold;
    color: ${props => props.theme.colors.blue};
    display:inline-block;
  }
  & a:hover {
    text-decoration: none;
    font-weight: bold;
    color: ${props => props.theme.colors.purple};
  }
  & p {
    color: ${props => props.theme.colors.primary};
  }
`;

const FooterHeader = styled.div`
  color: ${props => props.theme.colors.main};
  margin-top:20px;
  font-weight: bold;
  margin-bottom:20px;
  font-size:1.2em;
`;

const FooterLink = styled.div`
  margin-bottom:1.2em;
`;

export const SjFooter = () => (
  <Footer>
    <SjContainer style={{ maxWidth: 1200, marginLeft: 'auto', marginRight: 'auto' }}>
      <SjRow>
        <SjCol sm={6} md={3}>
          <FooterHeader>Stolpejakten</FooterHeader>
          <FooterLink><Link to="/omraader" aria-label="Områder" title="Områder">Områder</Link></FooterLink>
          <FooterLink><Link to="/konseptet" aria-label="Konseptet" title="Konseptet">Konseptet</Link></FooterLink>
          <FooterLink><Link to="/organisasjon" aria-label="Organisasjon" title="Organisasjon">Organisasjon</Link></FooterLink>
        </SjCol>
        <SjCol sm={6} md={3}>
          <FooterHeader>Bruker</FooterHeader>
          <FooterLink><Link to="/ny" aria-label="Ny bruker" title="Ny bruker">Ny bruker</Link></FooterLink>
          <FooterLink><Link to="/logginn" aria-label="Logg inn" title="Logg inn">Logg inn</Link></FooterLink>
          <FooterLink><Link to="/privacy" aria-label="Personvernerklæring" title="Personvernerklæring">Personvernerklæring</Link></FooterLink>
        </SjCol>
        <SjCol sm={6} md={3}>
          <FooterHeader>Hjelp & kontakt</FooterHeader>
          <FooterLink><Link to="/hjelp" aria-label="Brukerstøtte" title="Brukerstøtte">Brukerstøtte</Link></FooterLink>
          <FooterLink><Link to="/kontakt" aria-label="Kontakt" title="Kontakt">Kontakt</Link></FooterLink>
        </SjCol>
        <SjCol sm={6} md={3}>
          <p style={{ fontWeight: 'bold' }}>
            Utviklet av <a href="https://andmark.no" rel="noopener noreferrer" target="_blank" title="andmark.no">AndMark AS</a> for Stolpejakten. © {new Date().getFullYear()}
          </p>
          {/*<p>
            Nettsiden benytter Google Analytics som gjør nytte av informasjonskapsler
          </p>*/}
        </SjCol>
      </SjRow>
    </SjContainer>
  </Footer>
)