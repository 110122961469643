import React from "react";
import { connect } from "react-redux";
import { store } from "../redux/store";
import { setAuthorized, setModalState, setSnackbarState } from "../redux/actions";
import { SjSnackbarInner } from "./snackbar";

interface InjectedProps {
  history?: any;
}

export const withAuthorization = <BaseProps extends InjectedProps>(
  BaseComponent: React.ComponentType<BaseProps>
) => {
  const mapStateToProps = (state: any) => ({
    authorized: state.authorized
  });

  const dispatchProps = {
    setAuthorized: (val: boolean) => store.dispatch(setAuthorized(val))
  };

  type HocProps = ReturnType<typeof mapStateToProps>;

  class Hoc extends React.Component<HocProps> {
    static readonly WrappedComponent = BaseComponent;

    render() {
      const { authorized, ...restProps } = this.props;

      return (
        <BaseComponent
          authorized={authorized} // injected
          {...(restProps as BaseProps)}
        />
      );
    }
  }

  const ConnectedHoc = connect<
    ReturnType<typeof mapStateToProps>,
    typeof dispatchProps,
    BaseProps
  >(
    mapStateToProps,
    dispatchProps
  )(Hoc);

  return ConnectedHoc;
};



export const requiresAuthorizatiion = <BaseProps extends InjectedProps>(
  BaseComponent: React.ComponentType<BaseProps>
) => {
  const mapStateToProps = (state: any) => ({
    authorized: state.authorized,
  });

  type HocProps = ReturnType<typeof mapStateToProps>;

  const Hoc = (props: any) => {
    const { authorized, ...restProps } = props;

    if (!authorized) {
      window.location.href = '/logginn?returnUrl=' + encodeURI(window.location.pathname);
      return null;
    }

    return (
      <BaseComponent
        authorized={authorized} // injected
        {...(restProps as BaseProps)}
      />
    );
  }

  const ConnectedHoc = connect<
    ReturnType<typeof mapStateToProps>,
    BaseProps
  >(
    mapStateToProps,
  )(Hoc);

  return ConnectedHoc;
};


export const withModal = <BaseProps extends InjectedProps>(
  BaseComponent: React.ComponentType<BaseProps>
) => {
  const mapStateToProps = (state: any) => ({
    modalOpen: state.modalOpen,
    modalContent: state.modalContent,
    confirmClose: state.confirmClose,
    confirmCloseText: state.confirmCloseText
  });

  const dispatchProps = {
    setModalState: (open: boolean, content: any, confirmClose: boolean = false, confirmCloseText: string = '') => store.dispatch(setModalState(open, content, confirmClose, confirmCloseText))
  };

  type HocProps = ReturnType<typeof mapStateToProps>;

  const Hoc = (props: HocProps) => {
    const { modalOpen, modalContent, confirmClose, confirmCloseText, ...restProps } = props;
    return (
      <BaseComponent
        confirmClose={confirmClose}
        confirmCloseText={confirmCloseText}
        modalOpen={modalOpen}
        modalContent={modalContent}
        {...(restProps as BaseProps)}
      />
    );
  }

  const ConnectedHoc = connect<
    ReturnType<typeof mapStateToProps>,
    typeof dispatchProps,
    BaseProps
  >(
    mapStateToProps,
    dispatchProps,
  )(Hoc);

  return ConnectedHoc;
};



export const withSnackbar = <BaseProps extends InjectedProps>(
  BaseComponent: React.ComponentType<BaseProps>
) => {
  const mapStateToProps = (state: any) => ({
    snackbarOpen: state.snackbarOpen,
    snackbarText: state.snackbarText
  });

  const dispatchProps = {
    setSnackbarState: (snackbarOpen: boolean, snackbarText: string) => store.dispatch(setSnackbarState(snackbarOpen, snackbarText))
  };

  type HocProps = ReturnType<typeof mapStateToProps>;

  const Hoc = (props: HocProps) => {
    const { snackbarOpen, snackbarText, ...restProps } = props;
    return (
      <BaseComponent
        snackbarOpen={snackbarOpen}
        snackbarText={snackbarText}
        {...(restProps as BaseProps)}
      />
    );
  }

  const ConnectedHoc = connect<
    ReturnType<typeof mapStateToProps>,
    typeof dispatchProps,
    BaseProps
  >(
    mapStateToProps,
    dispatchProps,
  )(Hoc);

  return ConnectedHoc;
};