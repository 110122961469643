import React, { useState } from 'react';
import { SjSelect } from './select';

const YOBS: any[] = [];
let i = new Date().getFullYear();
const to = new Date().getFullYear() - 100;
while (i > to) {
  YOBS.push({ key: i, value: i, text: i });
  i = i - 1;
}

interface IProps {
  onChange: any;
  value?: any;
  disabled?: boolean;
}

export const SjYobPicker = (props: IProps) => {
  const [yob, setYob] = useState(props.value || '-1');

  const onChange = (key: any) => {
    setYob(key);
    props.onChange(key);
  }

  return (
    <SjSelect title="Velg fødselsår"
      defaultValue={yob}
      options={YOBS}
      disabled={props.disabled}
      onChange={onChange} />
  )
}


const GENDERS: any[] = [
  { key: '1', value: '1', text: 'Mann' },
  { key: '2', value: '2', text: 'Kvinne' },
  { key: '3', value: '3', text: 'Ønsker ikke oppgi' }
];

export const SjGenderPicker = (props: IProps) => {
  const [gender, setGender] = useState(props.value || '-1');

  const onChange = (key: any) => {
    setGender(key);
    props.onChange(key);
  }

  return (
    <SjSelect title="Velg kjønn"
      defaultValue={gender}
      options={GENDERS}
      disabled={props.disabled}
      onChange={onChange} />
  )
}