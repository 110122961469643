import React from 'react';
import styled from "styled-components";
import { Label } from './input';


const Datalist = styled.datalist`
  color: ${props => props.theme.colors.blue};
  font-size: 18px;
  width: 100%;
`;

const Option = styled.option`
  color: ${props => props.theme.colors.blue};
  font-size: 18px;
  width: 100%;
`;

const Input = styled.input`
  color: ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.black};
  display: inline-block;
  padding: 20px 15px;
  font-size: 1.2em;
  font-weight: bold;
  width:100%;
  display: block;
  margin-bottom:10px;
  border: 1px solid ${props => props.theme.colors.primary};
  flex:1;
  box-sizing : border-box;
  outline-offset: 0;
  border-radius: 3px;
  margin-left:0;
  margin-right:0;
  &:focus {
    outline-style: solid;
    outline-width: 3px;
    outline-color: ${props => props.theme.colors.blue};
    outline-offset: -2px;
  }
`;

const Container = styled.div`
  display:flex;
  flex-direction: column;
`;


export interface IProps {
  options: any[];
  placeholder: string;
  label: string;
  onChange: Function;
  id: string;
}

export const SjDataList = (props: IProps) => {
  return (
    <Container>
      <Label>{props.label}</Label>
      <Input list={props.id} placeholder="Velg kart" name={props.id} />
      <Datalist id={props.id}>
        {props.options.map((o: any) => <Option onClick={() => props.onChange(o.key)} key={o.key} value={o.text} />)}
      </Datalist>
    </Container>
  )
}