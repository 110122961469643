import React from 'react';
import { SjStorage } from '../Utils/storage';
import { Theme, ThemeContext } from './themeContext';
import { ThemeProvider } from 'styled-components';

export const SjThemeProvider = ({ children }: any) => {
  const isDark = SjStorage.getItem('dark_mode') === 'true';
  const [themeState, setThemeState] = React.useState({ dark: isDark, hasThemeMounted: true });

  const toggle = () => {
    const dark = !themeState.dark;
    SjStorage.setItem('dark_mode', dark ? 'true' : 'false');
    setThemeState({ ...themeState, dark });
  }

  if (!themeState.hasThemeMounted) {
    return <div />;
  }

  const currentTheme = themeState.dark ? Theme('dark') : Theme('light');

  return (
    <ThemeProvider theme={currentTheme}>
      <ThemeContext.Provider value={{ dark: themeState.dark, toggle }}>
        {children}
      </ThemeContext.Provider>
    </ThemeProvider>
  )
}