import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { withSnackbar } from './reduxHoc';

interface IProps {
  theme?: any;
  open: boolean;
}

const Snackbar = styled.div<IProps>`
  opacity: ${props => props.open ? 1 : 0};
  min-width: 300px; /* Set a default minimum width */
  margin-left: -150px; /* Divide value of min-width by 2 */
  background-color: ${props => props.theme.colors.primary};
  text-align: center;
  border-radius: 2px;
  padding: 12px; /* Padding */
  padding-bottom: 8px;
  position: fixed; 
  min-height: 20px;
  left: 50%;
  bottom: 20px;
  transition: opacity 0.5s ease-in;
`;

const Text = styled.div`
  color: ${props => props.theme.colors.black};
  display: inline-block;
  margin-bottom:10px;
  font-size: 1.4em
`;



export const SjSnackbarInner = (props: any) => {
  const { snackbarOpen, snackbarText, setSnackbarState } = props;
  useEffect(() => {
    if (snackbarOpen) {
      setTimeout(() => {
        setSnackbarState(false);
      }, 5000);
    }
  }, [snackbarOpen]);

  return (
    <Snackbar open={snackbarOpen}><Text>{snackbarText}</Text></Snackbar>
  )
}

const SjSnackbar = withSnackbar(SjSnackbarInner);
export { SjSnackbar }