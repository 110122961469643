import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTheme } from './Themes/themeContext';
import { SjToggle } from './Components/toggle';
import { SjIcon } from './Components/icon';
import { Link } from 'react-router-dom';
import { withAuthorization } from './Components/reduxHoc';

const Nav = styled.div`
  position:fixed;
  top:0;
  left:0;
  right:0;
  z-index: 99999;
  width:100%;
  background-color: #fff;
  height:87px;
  user-select:none;
`;

const NavLabel = styled("div") <{ open: boolean, authorized?: boolean }>`
  display: ${(props: any) => props.open && props.authorized ? 'inline-block' : 'none'};
  font-size:1.1em;
  padding-left: 15px;
  margin-top:30px;
  font-weight:bold; 
  color: ${props => props.theme.colors.blue};
`;

const NavHeader = styled("div") <{ open: boolean }>`
  padding: 12px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  border-bottom: 1px solid ${(props) => props.theme.colors.backgroundColor};
  background-color: ${(props) => props.theme.colors.black};
  box-shadow: ${(props) => `0px 0px 8px 2px ${props.theme.colors.card.white}`};
`;

const NavLeft = styled.div`
  text-align: left;
  margin-left:10px;
  flex:0;
  @media (max-width: 1100px) {
    display: none;
  }
`;

const NavRight = styled.div`
  text-align: right;
  flex:0 1 auto;
  flex-direction:'column';
  display:flex;
  justify-content:flex-end;
  margin-right:20px;
  @media (max-width: 1100px) {
    flex: 1 0 auto;
    justify-content:flex-end;
  }
  @media (max-width: 480px) {
    span {
      display: none;
    }
  }
`;

const NavCenter = styled("div") <{ open: boolean }>`
  flex:1;
  text-align: center;
  flex-flow: row nowrap;
  display:flex;
  @media (max-width: 1100px) {
    overflow-x:auto;
    max-height:100vh;
    flex: 0;
    text-align: left;
    flex-flow: column nowrap;
    align-items: flex-start;
    background-color: ${props => props.theme.colors.black};
    position: absolute;
    display: ${({ open }) => open ? 'flex' : 'none'};
    top: 88px;
    left: 0;
    height: 100vh;
    width:240px;
    z-index: 1000;
    transition: display 0.3s ease-in-out;
  }
`;

type MenuLinkProps = {
  authorized?: boolean | string,
  active?: boolean,
  children: any,
  style?: React.CSSProperties
}

const MenuLink = (props: MenuLinkProps) => {

  return (
    <StyledMenuLink authorized={props.authorized?.toString()} active={props.active?.toString()} style={props.style}>
      {props.children}
    </StyledMenuLink>
  )
}

const StyledMenuLink = styled.div<any>`
  padding: 20px;
  padding-left:12px;
  padding-right:12px;
  font-weight:  ${props => props.theme.font.weight.bold};
  font-size:1.0em;
  flex: 0 1 auto;
  white-space: nowrap;
  display: ${({ authorized }) => authorized === 'none' ? 'inline-block' : (authorized === 'true' ? 'inline-block' : 'none')};
  a {
    color: ${props => props.active === "true" ? props.theme.colors.main : props.theme.colors.primary};
    text-decoration:none;
    user-select: none; 
    &:hover {
      color: ${props => props.theme.colors.main};
    }
  }
  @media (max-width: 1100px) {
    padding:15px;
  }
`;

const StyledBurger = styled('div') <{ open: boolean }>`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 30px;
  left: 20px;
  z-index: 20;
  display: none;
  @media (max-width: 1100px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${(props: any) => props.open ? '#ccc' : '#333'};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${(props: any) => props.open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
      transform: ${(props: any) => props.open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${(props: any) => props.open ? 0 : 1};
    }
    &:nth-child(3) {
      transform: ${(props: any) => props.open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;

const Burger = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <RightNav open={open} setOpen={setOpen} />
    </>
  )
}

function WrappedRightNav(props: any) {
  const themeState = useTheme();
  const split = window.location.pathname.split('/');
  const [activeItem, setActiveActive] = useState('/' + split[1]);

  useEffect(() => {
    if (themeState.dark) {
      document.body.style.backgroundColor = 'black';
    } else {
      document.body.style.backgroundColor = 'white';
    }
  }, [themeState.dark]);

  const onItemClick = (e: any) => {
    const link = e.target.getAttribute('href');
    if (link === "/nettbutikk") {
      e.preventDefault();
      window.open("https://vekstbutikken.no", "_blank");
      return;
    }
    props.setOpen(false);
    setActiveActive(link);
  }

  return (
    <NavHeader open={props.open}>
      <NavCenter open={props.open}>
        <MenuLink authorized='none' active={activeItem === "/"}>
          <Link to="/" aria-label="Fremsiden" title="Fremsiden" onClick={onItemClick}>
            <SjIcon icon="home" />
          </Link>
        </MenuLink>
        <MenuLink authorized='none' active={activeItem === "/omraader" || activeItem === "/arrangor"}>
          <Link to="/omraader" aria-label="Områdeoversikt" title="Områder" onClick={onItemClick}>
            Områder
          </Link>
        </MenuLink>
        <MenuLink authorized='none' active={activeItem === "/nyheter"}>
          <Link to="/nyheter" aria-label="Nyheter" title="Nyheter" onClick={onItemClick}>
            Nyheter
          </Link>
        </MenuLink>
        <MenuLink authorized='none' active={activeItem === "/konseptet"}>
          <Link to="/konseptet" aria-label="Konseptet" title="Konseptet" onClick={onItemClick}>
            Om
          </Link>
        </MenuLink>
        <MenuLink authorized='none' active={activeItem === "/hjelp"}>
          <Link to="/hjelp" aria-label="Brukerstøtte" title="Brukerstøtte" onClick={onItemClick}>
            Brukerstøtte
          </Link>
        </MenuLink>
        <MenuLink authorized={props.authorized} active={activeItem === "/profil"}>
          <Link to="/profil" aria-label="Profil" title="Profil" onClick={onItemClick}>
            Profil
          </Link>
        </MenuLink>
        <MenuLink authorized={props.authorized} active={activeItem === "/stolperegistrering"}>
          <Link to="/stolperegistrering" aria-label="Registrer besøk" title="Registrer besøk" onClick={onItemClick}>
            Registrer besøk
          </Link>
        </MenuLink>
        <MenuLink authorized={props.authorized} active={activeItem === "/topplister"}>
          <Link to="/topplister" aria-label="Topplister" title="Topplister" onClick={onItemClick}>
            Topplister
          </Link>
        </MenuLink>
        <MenuLink authorized={props.authorized} active={activeItem === "/kart"}>
          <Link to="/kart" aria-label="Last ned kart" title="Last ned kart" onClick={onItemClick}>
            Kart
          </Link>
        </MenuLink>
        <MenuLink authorized={props.authorized} active={activeItem === "/ruter"}>
          <Link to="/ruter" aria-label="Ruter" title="Ruter" onClick={onItemClick}>
            Ruter
          </Link>
        </MenuLink>
        <MenuLink authorized='none' active={false}>
          <Link to="/nettbutikk" aria-label="Nettbutikk" title="Nettbutikk" onClick={onItemClick}>
            Nettbutikk
          </Link>
        </MenuLink>
      </NavCenter>
      <NavRight>
        <div style={{ display: 'flex', flex: 0, alignItems: 'center', marginRight: 10, padding: 20, paddingBottom: 0, paddingTop: 10, verticalAlign: 'middle' }}>
          <SjToggle style={{ marginBottom: !props.authorized ? 2 : 6 }} defaultValue={themeState.dark} onChange={() => themeState.toggle()} />
          <SjIcon icon={themeState.dark ? 'moon' : 'sun'} style={{ color: themeState.dark ? '#f8f8f8' : '#424242', marginBottom: !props.authorized ? 7 : 12 }} />
        </div>
        <MenuLink authorized={!props.authorized} style={{ padding: 20, marginTop: 2 }}>
          <Link aria-label="Logg inn" to="/logginn" title="Logg inn">
            <span style={{ marginRight: 5 }}>Logg inn</span>
            <SjIcon icon="sign-in-alt" />
          </Link>
        </MenuLink>
        <MenuLink authorized={!props.authorized} style={{ padding: 20, marginTop: 2 }} >
          <Link aria-label="Opprett ny bruker" to="/ny" title="Ny bruker">
            <span style={{ marginRight: 5 }}>Ny bruker</span>
            <SjIcon icon="plus" />
          </Link>
        </MenuLink>
        <MenuLink authorized={props.authorized} style={{ padding: 20 }} >
          <Link aria-label="Logg ut" to="/loggut" title="Logg ut">
            <SjIcon icon="sign-out-alt" />
          </Link>
        </MenuLink>
      </NavRight>
    </NavHeader>
  )
}

const RightNav = withAuthorization(WrappedRightNav);

export function SjNavBar() {

  return (
    <Nav>
      <Burger />
    </Nav>
  );
}