import React, { useState } from 'react';
import { SjPageContainer, SjTooltip, SjText, SjHeading, SjKommunePicker, SjError, SjLoadingIndicator, TableContainer, Table, Tr, THead, TBody, Th, Td, SjButton } from '../../Components';
import { PdfsAPI } from '../../http/pdfs';
import { mobileDetect } from '../../Utils/mobileDetect';
import { SjLogger } from '../../Utils/logger';

export const SjDownloadPdfsPage = () => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [downloadUrl, setUrl] = useState('');
  const [activeKommuneId, setActiveKommuneId] = useState(-1);

  const onKommuneChange = async (kommune: any) => {
    if (kommune.id === '-1' || isNaN(Number(kommune.id))) {
      setLoading(false);
      return;
    }

    setActiveKommuneId(kommune.id);
    if (kommune.id == '-1') {
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const { results } = await PdfsAPI.getPdfsInKommune(kommune.id);
      setData(results);
      setLoading(false);
    } catch (e) {
      setError('En feil oppstod. Kunne ikke hente ut kart');
      setLoading(false);
    }
  }

  const download = async (id: string) => {
    try {
      const { url } = await PdfsAPI.download(id);
      setUrl(url);
      if (!mobileDetect.mobile()) {
        window.open(url, '_blank');
      }
    } catch (e) {
      setError('En feil oppstod. Kunne ikke laste ned kart');
    }
  }

  return (
    <SjPageContainer>
      <SjHeading h="h1">Last ned kart</SjHeading>

      <div style={{ maxWidth: 600 }}>
        <SjTooltip>Velg et fylke og by/sted/kommune du ønsker å laste ned kart over</SjTooltip>
        <SjKommunePicker onChange={onKommuneChange} useLastUsed />
        <SjError message={error} />
        <SjLoadingIndicator loading={loading} />
      </div>
      {!loading && !data.length && activeKommuneId > -1 ? <SjText>Ingen tilgjengelige kart du kan laste ned. <br />Det kan være at ingen områder har startet opp enda, eller at arrangøren ikke har kartfiler til nedlasting.</SjText> : null}
      {data.length ? <TableContainer style={{ maxWidth: 600 }}>
        <Table condensed>
          <TBody>
            {data.map((i: any) => (
              <Tr key={i.file_name}>
                <Td>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15 }}>
                    <SjText bold>{i.display_name}</SjText>
                    <SjButton iconLeft icon="file-download" color="blue" buttonStyle={{ paddingTop: 8, paddingBottom: 8, paddingRight: 10, paddingLeft: 10, maxHeight: 38 }} title="Last ned" ariaLabel={"Last ned kart " + i.display_name} onClick={() => download(i.id)} />
                  </div>
                  <div>
                    <SjText>{i.description}</SjText>
                    {downloadUrl ? <SjText bold>Hvis filen ikke ble lastet ned, kan du trykke <a href={downloadUrl} target="_blank">her</a></SjText> : null}
                  </div>


                </Td>
              </Tr>
            ))}
          </TBody>
        </Table>
      </TableContainer> : null}
    </SjPageContainer>
  )
}