import React from 'react';
import Configuration from './configuration-web.json';

type Ad = {
  link: string;
  image: string;
  id: string;
}

export const AdSpace = () => {

  const [ads, setAds] = React.useState<Array<Ad>>([]);
  const [currentAdNumber, updateCurrentAdCounter] = React.useState(0);

  const updateCurrentAdNumber = (maxLen: number) => {
    var i = Math.round(Math.random() * (maxLen * 10)) % maxLen;
    updateCurrentAdCounter(i);
  }

  React.useEffect(() => {
    try {
      if (Configuration.length > 0) {
        updateCurrentAdNumber(Configuration.length);
        setAds(Configuration);
      }
    } catch (_) {

    }
  }, []);

  if (!ads?.length || currentAdNumber - 1 > ads?.length) {
    return null;
  }

  const currentAd = ads[currentAdNumber];

  if (!currentAd) {
    return null;
  }

  return (
    <div style={{ width: '100%' }}>
      <div style={style.root} onClick={() => window.open(currentAd.link, '_blank')}>
        <img src={currentAd.image} alt="spons" style={style.img} />
      </div>
    </div>
  )

}


const style = {
  root: {
    maxHeight: 250,
    height: 'auto',
    maxWidth: 1200,
    width: '100%',
    cursor: 'pointer'
  },
  img: {
    width: '100%',
    height: 'auto'
  }
}