import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { HttpHelper } from '../http/http';
import { SjError } from './error';
import { SjSelect } from './select';
import { SjStorage } from '../Utils/storage';
import { parse } from 'path';
import { SjLogger } from '../Utils/logger';

const defFylke: any = { key: '-1' };
const defKommmune: any = { key: '-1' };

interface IProps {
  onChange: any;
  useLastUsed: boolean;
  disabled?: boolean;
}

export const SjKommunePicker = (props: IProps) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [kommuner, setKommuner] = useState(Array<any>());
  const [fylker, setFylker] = useState(Array<any>());
  const [activeFylke, setActiveFylke] = useState(defFylke);
  const [activeKommune, setActiveKommune] = useState(defKommmune);

  useEffect(() => {
    const load = async () => {
      try {
        const { results } = await HttpHelper.GET('fylker/app');
        const tempFylker: any = [];
        const tempKommuner: any = [];

        for (let i = 0; i < results.length; i++) {
          tempFylker.push({
            key: '' + results[i].id,
            value: '' + results[i].id,
            text: results[i].name
          });
          for (let j = 0; j < results[i].kommuner.length; j++) {
            tempKommuner.push({
              key: '' + results[i].kommuner[j].id,
              value: '' + results[i].kommuner[j].id,
              text: results[i].kommuner[j].name,
              fylke: '' + results[i].kommuner[j].fylke
            });
          }
        }

        tempFylker.sort((a: any, b: any) => a.text < b.text ? -1 : (a.text > b.text ? 1 : 0));
        tempKommuner.sort((a: any, b: any) => a.text < b.text ? -1 : (a.text > b.text ? 1 : 0));

        if (tempFylker.length && tempKommuner.length) {
          setKommuner(tempKommuner);
          setFylker(tempFylker);
          if (!props.useLastUsed) {
            const user = SjStorage.getItem('me', '');
            const parsed = JSON.parse(user);
            const storedK = tempKommuner.find((k: any) => k.key == parsed.kommune);
            SjLogger.log('tempKommuner', tempKommuner);
            if (typeof storedK !== 'undefined' && storedK) {
              setActiveFylke(tempFylker.find((k: any) => k.key == storedK.fylke));
              setActiveKommune(storedK);
              props.onChange({ id: storedK.key });
            }
          } else {
            const storedK = SjStorage.getItem('last_used_kommune', '');
            const storedF = SjStorage.getItem('last_used_fylke', '');
            if (storedK && storedF) {
              setActiveFylke(tempFylker.find((k: any) => k.key === storedF));
              setActiveKommune(tempKommuner.find((k: any) => k.key === storedK));
              props.onChange({ id: storedK });
            } else {
              props.onChange({ id: -1 });
            }
          }
        } else {
          setError('Kunne ikke laste fylker og kommuner');
        }

      } catch (e) {
        setError('Kunne ikke laste fylker og kommuner');
      }
      setLoading(false);
    }
    load();
  }, []);

  const onFylkeChange = (id: string) => {
    const fylke = fylker.find((f: any) => f.key === id);
    if (!!fylke) setActiveFylke(fylke);
    else setActiveFylke(defFylke);
  }

  const onKommuneChange = (id: string) => {
    const kommune = kommuner.find((f: any) => f.value === id);
    props.onChange({ id, name: kommune.text });
    SjStorage.setItem('last_used_kommune', id);
    SjStorage.setItem('last_used_fylke', kommune.fylke);
  }

  if (loading)
    return null;

  if (error) {
    return <SjError message={error} />
  }

  if (!fylker.length || !kommuner.length) {
    return <div style={{ width: '100%' }}><SjError message={'Kunne ikke laste fylker og kommuner'} /></div>
  }

  return (
    <div style={{ width: '100%' }}>
      <SjSelect ariaLabel="Velg fylke" title="Velg fylke" defaultValue={activeFylke.key} options={fylker} onChange={onFylkeChange} />
      {activeFylke.key !== '-1' ?
        <SjSelect ariaLabel="Velg fylke" title="Velg kommune" defaultValue={activeKommune.key} options={kommuner.filter((k) => k.fylke === activeFylke.key)} onChange={onKommuneChange} /> : null}
    </div>
  )
}