import React, { useState, useEffect, useMemo, useCallback } from 'react';
import './App.css';
import { SjNavBar } from './Navbar';
import { SjThemeProvider } from './Themes/themeProvider';
import { SjContent } from './Content';
import { SjFooter } from './Footer';
import { BrowserRouter } from "react-router-dom";
import 'moment/locale/nb';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { setAuthorized } from './redux/actions';
import { UserApi } from './http/user';
import { ModalProvider } from 'styled-react-modal';
import { SjModal } from './Components/modal';
import { SjLoadingIndicator } from './Components/loading';
import { SjLogger } from './Utils/logger';
import { SjSnackbar, SjButton } from './Components';
import { HelmetProvider } from 'react-helmet-async'

const IE11Error = () => {
  const [showIeError, setShowIeError] = useState(true);
  const isIE11 = useCallback(() => !!(window as any).MSInputMethodContext && !!(document as any).documentMode, []);
  if (isIE11() && showIeError) {
    return (
      <div style={{ display: 'flex', padding: 20, paddingTop: 10, position: 'absolute', top: 90, left: 0, right: 0, zIndex: 2, height: 54, backgroundColor: 'red' }}>
        <div>
          <p style={{ fontWeight: 'bold', maxWidth: '100%' }}>Du bruker en eldre nettleser, og vil derfor kunne oppleve større og mindre problemer med nettsiden. Bruk en annen nettleser hvis du opplever problemer. F.eks Microsoft Edge, Chrome eller Firefox</p>
        </div>
        <div>
          <SjButton icon="times" ariaLabel="Lukk advarsel" onClick={() => setShowIeError(false)} />
        </div>
      </div>
    )
  }
  return null;
}

function App() {
  const [stateLoaded, setStateLoaded] = useState(false);
  useEffect(() => {
    const load = async () => {
      try {
        await UserApi.authorized();
        SjLogger.log('Authorized here');
        store.dispatch(setAuthorized(true));
      }
      catch (e) {
        SjLogger.log('Not authorized');
        store.dispatch(setAuthorized(false));
      }
      setStateLoaded(true);
    }
    load();
  }, []);

  return (
    <BrowserRouter>
      <HelmetProvider>
        <Provider store={store}>
          <SjThemeProvider>
            <ModalProvider>
              <div className="content-container">
                <SjNavBar />
                <IE11Error />
                <div style={{ maxWidth: 1200, marginLeft: 'auto', marginRight: 'auto' }}>
                  {stateLoaded ? <SjContent /> : <div style={{ flex: 1, minHeight: '100vh', paddingTop: 100 }}><SjLoadingIndicator loading={true} /></div>}
                </div>
                <SjFooter />
                <SjModal />
                <SjSnackbar />
              </div>
            </ModalProvider>
          </SjThemeProvider>
        </Provider>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
