import React from 'react';
import styled from "styled-components";
import { Link } from 'react-router-dom';

const StyledLink = styled.div`
  color: ${props => props.theme.colors.blue};
  display: inline-block;
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.colors.purple};
  }
`;

export const SjExternalLink = ({ children = "", href = '', style = {} }) =>
  <StyledLink><a rel="noopener noreferrer" href={href} title={href} target="blank" style={{ fontSize: 'inherit', color: 'inherit', textDecoration: 'inherit', ...style }}>{children}</a></StyledLink>

export const SjLink = ({ children = "", to = '' }) => {
  return <StyledLink><Link style={{ fontSize: 'inherit', color: 'inherit', textDecoration: 'inherit' }} to={to}>{children}</Link></StyledLink>
}