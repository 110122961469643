import React from "react";
import styled from "styled-components";

const H1 = styled.h1`
  color: ${props => props.theme.colors.blue};
  font-size: 2.5em;
  @media only screen and (max-width: 768px) {
    font-size: 2em;
  }
`;

const H2 = styled.h2`
  color: ${props => props.theme.colors.blue};
  font-size: 1.875em;
  @media only screen and (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const H3 = styled.h3`
  color: ${props => props.theme.colors.blue};
  font-size: 1.45em;
  @media only screen and (max-width: 768px) {
    font-size: 1.2em;
  }
`;

const H4 = styled.h4`
  color: ${props => props.theme.colors.blue};
  @media only screen and (max-width: 768px) {
    font-size: 1em;
  }
`;

const H5 = styled.h5`
  color: ${props => props.theme.colors.blue};
  @media only screen and (max-width: 768px) {
    font-size: 0.8em;
  }
`;

export const SjHeading = ({ children, h = "h1", style = {} }: any) => {
  if (h === 'h1') {
    return <H1 style={style}>{children}</H1>
  } else if (h === 'h2') {
    return <H2 style={style}>{children}</H2>
  } else if (h === 'h3') {
    return <H3 style={style}>{children}</H3>
  } else if (h === 'h4') {
    return <H4 style={style}>{children}</H4>
  } else if (h === 'h5') {
    return <H5 style={style}>{children}</H5>
  } else {
    throw new Error("Invalid 'h' option. Use h1, h2, h3, h4 or h5");
  }
}

